
















import Vue from 'vue'

export default Vue.extend({
	name: 'Error',
	props: {
		errorMessage: {
			type: String,
		},
		request: {
			type: String,
		},
	},
})
